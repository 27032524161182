var render = function render(){var _vm=this,_c=_vm._self._c;return _c('main',[_c('section',{staticClass:"section"},[_c('div',[_c('nav',{staticClass:"breadcrumb is-medium",attrs:{"aria-label":"breadcrumbs"}},[_c('ul',[_c('sqr-crumb',{attrs:{"name":"broker","bid":_vm.bid}},[_vm._v(_vm._s(_vm.$t('breadcrumb_broker', _vm.broker)))]),_c('sqr-crumb',{attrs:{"name":"relations","bid":_vm.bid,"label":"breadcrumb_relations"}}),_c('sqr-crumb',{attrs:{"name":"relation","bid":_vm.bid,"rid":_vm.rid}},[_vm._v(_vm._s(_vm.$t('breadcrumb_relation', _vm.relation)))]),_c('sqr-crumb',{attrs:{"name":"relation-bank-account","bid":_vm.bid,"rid":_vm.rid}},[_vm._v(_vm._s(_vm.$t('breadcrumb_relation_bank_account', _vm.relation)))])],1)])])]),(!_vm.loading && !_vm.error)?_c('section',{staticClass:"section"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"columns is-centered"},[_c('div',{staticClass:"column is-4"},[(_vm.bankAccount)?_c('div',[_c('div',{staticClass:"field"},[_c('sqr-input-text',{attrs:{"label":"relation_bank_account_company","value":_vm.bankAccount.company},on:{"change":function($event){return _vm.fieldSet({
                    group: 'bankAccount',
                    field: 'company',
                    value: $event,
                  })}}})],1),_c('div',{staticClass:"field"},[_c('sqr-input-text',{attrs:{"label":"relation_bank_account_iban","value":_vm.bankAccount.iban,"valid":_vm.ibanValid,"invalid":!_vm.ibanValid},on:{"input":function($event){return _vm.fieldSet({
                    group: 'bankAccount',
                    field: 'iban',
                    value: $event,
                  })}}})],1),_c('div',{staticClass:"field"},[_c('sqr-input-textarea',{attrs:{"label":"relation_bank_account_note","value":_vm.bankAccount.note},on:{"change":function($event){return _vm.fieldSet({
                    group: 'bankAccount',
                    field: 'note',
                    value: $event,
                  })}}})],1),_c('div',{staticClass:"field"},[_c('div',{staticClass:"buttons is-pulled-right"},[_c('sqr-button',{attrs:{"label":"buttons_cancel","color":"light","size":"medium"},on:{"click":function($event){return _vm.$router.back()}}}),_c('sqr-button',{attrs:{"label":"buttons_save","color":"primary","size":"medium","is-loading":_vm.saving},on:{"click":function($event){return _vm.saveAndGo()}}})],1)])]):_vm._e()])])])]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }